import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ProFormText } from '@ant-design/pro-form';
import { useModalForm } from '../../shared/hooks';
import { useCreateCategory } from '../useCategories';

export const CreateCategory = () =>
  useModalForm({
    title: 'Nova categoria',
    trigger: (
      <Button type='primary' icon={<PlusOutlined />}>
        Criar categoria
      </Button>
    ),
    formFields: () => [
      <ProFormText
        key='name'
        name='name'
        label='Nome'
        rules={[{ required: true }]}
      />,
    ],
    mutation: useCreateCategory(),
  });
