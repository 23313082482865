import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, RequireUnauth, ProtectedRoutes } from './shared/router';
import { Admin } from './users/userRole';
import { HomeAuthenticated } from './home';
import Login from './auth/pages/Login';
import Users from './users/pages/Users';
import Categories from './categories/pages/Categories';
import Pictures from './pictures/pages/Pictures';

export function Navigation() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route
          path='/entrar'
          element={
            <RequireUnauth>
              <Login />
            </RequireUnauth>
          }
        />
        <Route path='admin' element={<ProtectedRoutes roleRequired={Admin} />}>
          <Route element={<HomeAuthenticated />} index />
          <Route path='usuarios' element={<Users />} />
          <Route path='categorias' element={<Categories />} />
          <Route path='fotos' element={<Pictures />} />
        </Route>
      </Routes>
    </Router>
  );
}
