import { Layout } from 'antd';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Pilege Moveis Planejados ©{currentYear}.
    </Layout.Footer>
  );
}
