import { message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { fetchPictures, importPicture, deletePicture } from './picturesApi';
import { queryClient } from '../shared/queryClient';

export function usePictures(filter) {
  if (filter && filter !== 'all') {
    return useQuery(['pictures', filter], ({ queryKey }) =>
      fetchPictures(queryKey[1])
    );
  }

  return useQuery('pictures', () => fetchPictures());
}

export function useCreatePicture() {
  return useMutation(
    ({ file, categoryId }) => {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('categoryId', categoryId);
      return importPicture(formData);
    },
    {
      onSuccess: () => {
        message.success('Nova foto cadastrada com sucesso!');
        queryClient.invalidateQueries('pictures');
      },
    }
  );
}

export async function useDeletePicture(pictureId) {
  await deletePicture(pictureId);
  queryClient.invalidateQueries('pictures');
}
