import { UserRole } from '../users/userRole';

export class UserAuthenticated {
  constructor(data) {
    this.username = data.username;
    this.role = new UserRole(data.role);
  }

  get displayName() {
    return this.username;
  }

  get isAdmin() {
    return this.role.isAdmin;
  }

  get isUser() {
    return this.role.isUser;
  }
}
