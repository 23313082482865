import { PageContainer } from '@ant-design/pro-layout';
import { CreatePicture } from '../../components/CreatePicture';
import PicturesList from '../../components/PicturesList';

export default function Users() {
  return (
    <PageContainer
      title='Fotos'
      subTitle='Gerenciar fotos do site'
      extra={<CreatePicture />}
    >
      <PicturesList />
    </PageContainer>
  );
}
