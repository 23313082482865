import { Image, Card, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeletePicture } from '../usePictures';

export default function PictureCard({ id, image, category }) {
  const confirm = async () => {
    await useDeletePicture(id);
    message.success('Imagem apagada com sucesso!');
  };

  return (
    <Card
      style={{ width: 300 }}
      bodyStyle={{ padding: 0 }}
      cover={<Image src={image} />}
      actions={[
        <span key='category'>{category}</span>,
        <Popconfirm
          title='Voce tem certeza que deseja apagar essa foto?'
          onConfirm={confirm}
          okText='Sim'
          cancelText='Nao'
          key='delete'
        >
          <DeleteOutlined />
        </Popconfirm>,
      ]}
      bordered
    />
  );
}
