import { PageContainer } from '@ant-design/pro-layout';
import { CreateCategory } from '../../components/CreateCategory';
import CategoriesList from '../../components/CategoriesList';

export default function Users() {
  return (
    <PageContainer
      title='Categorias'
      subTitle='Gerenciar categorias das fotos'
      extra={<CreateCategory />}
    >
      <CategoriesList />
    </PageContainer>
  );
}
