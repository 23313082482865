import { message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import {
  fetchCategories,
  createCategory,
  deleteCategory,
} from './categoriesApi';
import { queryClient } from '../shared/queryClient';

export function useCategories({ onSuccess = () => {} }) {
  return useQuery('categories', fetchCategories, {
    onSuccess,
  });
}

export function useCreateCategory() {
  return useMutation(createCategory, {
    onSuccess: () => {
      message.success('Categoria criado com sucesso');
      queryClient.invalidateQueries('categories');
    },
  });
}

export async function useDeleteCategory(categoryId) {
  await deleteCategory(categoryId);
  queryClient.invalidateQueries('categories');
}
