import { request } from '../shared/request';

export const fetchPictures = (categoryId) =>
  request.get('/pictures', { params: { categoryId } }).then((res) => res.data);

export const importPicture = (data) =>
  request
    .post('/pictures', data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);

export const deletePicture = (pictureId) =>
  request.delete(`/pictures/${pictureId}`).then((res) => res.data);
