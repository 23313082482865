import { Button, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ProForm, { ProFormSelect } from '@ant-design/pro-form';
import { useModalForm } from '../../shared/hooks';
import { useCategories } from '../../categories/useCategories';
import { useCreatePicture } from '../usePictures';

export const CreatePicture = () => {
  const categories = useCategories({});

  const categoriesEnum = (categories.data || []).reduce((pv, cv) => {
    pv[cv.id] = cv.name;
    return pv;
  }, {});

  return useModalForm({
    title: 'Nova foto',
    trigger: (
      <Button type='primary' icon={<PlusOutlined />}>
        Postar foto
      </Button>
    ),
    formFields: () => [
      <ProFormSelect
        key='categoryId'
        name='categoryId'
        label='Categoria'
        valueEnum={categoriesEnum}
        rules={[{ required: true }]}
      />,
      <ProForm.Item
        key='file'
        name='file'
        label='Foto'
        rules={[{ required: true }]}
      >
        <Upload accept='image/*' beforeUpload={() => false} maxCount={1}>
          <Button icon={<UploadOutlined />}>Selecionar foto</Button>
        </Upload>
      </ProForm.Item>,
    ],
    mutation: useCreatePicture(),
  });
};
