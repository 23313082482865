import { request } from '../shared/request';

export const fetchCategories = () =>
  request.get('/categories').then((res) => res.data);

export const updateCategoriesOrder = (categories) =>
  request.put('/categories', categories).then((res) => res.data);

export const createCategory = (data) => request.post('/categories', data);

export const deleteCategory = (categoryId) =>
  request.delete(`/categories/${categoryId}`).then((res) => res.data);
