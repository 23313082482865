import {
  AppstoreOutlined,
  HomeOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Admin } from '../../users/userRole';

export function getMenuItems({ role }) {
  const adminItems = [
    {
      icon: <HomeOutlined />,
      name: 'Inicio',
      path: '/admin',
    },
    {
      icon: <AppstoreOutlined />,
      name: 'Categorias',
      path: '/admin/categorias',
    },
    {
      icon: <PictureOutlined />,
      name: 'Fotos',
      path: '/admin/fotos',
    },
  ];

  const clientItems = [
    {
      name: 'Dashboard',
      path: '/transportadora',
      exact: true,
    },
  ];

  return role.isEqual(Admin) ? adminItems : clientItems;
}
