import ReactDragListView from 'react-drag-listview';
import { Card, List, message, Popconfirm } from 'antd';
import { useState } from 'react';
import { useCategories, useDeleteCategory } from '../useCategories';
import { updateCategoriesOrder } from '../categoriesApi';
import { DeleteOutlined } from '@ant-design/icons';

export default function CategoriesList() {
  const [categories, setCategories] = useState([]);

  const { isLoading } = useCategories({
    onSuccess: (data) => {
      setCategories(data);
    },
  });

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...categories];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    setCategories(items);
    updateCategoriesOrder(
      items.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }))
    );
  };

  const confirm = async (id) => {
    await useDeleteCategory(id);
    message.success('Categoria apagada com sucesso!');
  };

  return (
    <Card>
      <ReactDragListView
        nodeSelector='.ant-list-item.draggble'
        onDragEnd={onDragEnd}
      >
        <List
          loading={isLoading}
          size='small'
          bordered
          dataSource={categories}
          rowKey='id'
          renderItem={(item, index) => {
            return (
              <List.Item
                actions={[
                  <Popconfirm
                    title='Voce tem certeza que deseja apagar essa categoria?'
                    onConfirm={() => confirm(item.id)}
                    okText='Sim'
                    cancelText='Nao'
                    key='delete'
                  >
                    <DeleteOutlined />
                  </Popconfirm>,
                ]}
                className={'draggble'}
              >
                <List.Item.Meta
                  title={item.name}
                  description={`Ordem: ${index + 1}`}
                />
              </List.Item>
            );
          }}
        />
      </ReactDragListView>
    </Card>
  );
}
