import { Image, List, Segmented } from 'antd';
import { usePictures } from '../usePictures';
import { useCategories } from '../../categories/useCategories';
import PictureCard from './PictureCard';
import { useState } from 'react';

export default function CategoriesList() {
  const [filter, setFilter] = useState('all');
  const { data, isLoading } = usePictures(filter);
  const categories = useCategories({});

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>
        {!categories.isLoading && (
          <Segmented
            options={[
              { label: 'Todos', value: 'all' },
              ...(categories.data || []).map((category) => ({
                label: category.name,
                value: category.id,
              })),
            ]}
            onChange={(value) => setFilter(value)}
          />
        )}
      </div>
      <Image.PreviewGroup>
        <List
          grid={{
            gutter: 16,
            column: 4,
          }}
          loading={isLoading}
          dataSource={data}
          rowKey='id'
          renderItem={(item) => {
            return (
              <List.Item>
                <PictureCard
                  id={item.id}
                  image={item.url}
                  category={item.category.name}
                />
              </List.Item>
            );
          }}
        />
      </Image.PreviewGroup>
    </>
  );
}
